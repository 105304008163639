import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
import SEOTools from '../components/SEOTools'
import { graphql } from 'gatsby'
import PostListing from '../components/PostListing'

const IndexPage = ({ data }) => {
    return (
        <>
            <SEOTools title='Home' />
            <section className='dark-background-overlay position-relative'>
                <div className='text-white mb-0 d-flex align-items-center full-screen-jumbo'>
                    <Container>
                        <Row>
                            <Col
                                xl={{ span: 4, offset: 4 }}
                                lg={{ span: 5, offset: 3 }}
                                md={{ span: 7, offset: 2 }}
                                sm={{ span: 7, offset: 1 }}
                                xs={{ span: 9, offset: 1 }}>
                                <h1 className='name mb-4 text-uppercase'>Siru Blog</h1>
                                <p className='message mb-5 pb-3'>
                                    Share the
                                    <strong> knowledge</strong>
                                </p>
                            </Col>
                        </Row>
                    </Container>
                    <StaticImage
                        className='z-index-n1 position-absolute w-100 h-100'
                        src='../images/sirumobile_frontpage_hero.jpg'
                        alt=''
                        role='presentation'
                        placeholder='blurred'
                        loading='eager'
                        layout='fullWidth'
                    />
                </div>
            </section>
            <section className='py-5'>
                <Container className='mt-4 mb-5'>
                    <Row>
                        <Col md='8'>
                            <h2>Latest articles</h2>
                            <PostListing related={data.latestPosts.nodes} truncateExcerptTo={230} />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export const query = graphql`
    query {
        latestPosts: allSanityPost(
            limit: 5
            filter: { lang: { eq: "en" } }
            sort: { fields: publishedAt, order: DESC }
        ) {
            nodes {
                title
                _rawBody
                slug {
                    current
                }
                lang
                mainImage {
                    alt
                    image {
                        ...ImageWithPreview
                    }
                }
            }
        }
    }
`

export default IndexPage
